import { defineStore } from 'pinia'
import ApiService from '@/common/api.service'

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useUser = defineStore('user', {
    state: () => {
        return {
            // all these properties will have their type inferred automatically
            userData: {},
            isLoggedIn: false,
        }
    },


    actions: {
        async login(email, password) {
            try {
                var result = await ApiService.post('/auth/login', { 'email': email, 'password': password });
                this.userData = result.user;
                this.isLoggedIn = true;
                return true;

            } catch (error) {
                error
                return false;
                
            }
        },
        async logout() {
            try {
                await ApiService.get('/auth/logout', null);
                this.userData = null;
                this.isLoggedIn = false;
            } catch (error) {
                return error
            }
        },
        async ping() {
            try {
                var result = await ApiService.get('/auth/ping', null);
                this.userData = result.user;
                this.isLoggedIn = true;
            } catch (error) {
                this.isLoggedIn = false;
                this.userData = null;
                return error
            }
        },
    }
})

