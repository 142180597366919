import { API_URL } from "@/common/config";

const ApiService = {

  init() { },

  get(resource, params = null) {
    return fetch(API_URL + resource + ((params) ? ("?" + new URLSearchParams(params)) : ('')), {
      method: 'get',
      headers: {
        'content-type': 'application/json'
      }
    })
      .then(res => {
        // a non-200 response code
        if (!res.ok) {
          // create error instance with HTTP status text
          const error = new Error(res.statusText);
          error.json = res.json();
          throw error;
        }

        return res.json();
      })
  },

  post(resource, params) {
    return fetch(API_URL + resource, {
      method: 'post',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(params),
    })
      .then(res => {
        // a non-200 response code
        if (!res.ok) {
          // create error instance with HTTP status text
          const error = new Error(res.statusText);
          error.json = res.json();
          throw error;
        }

        return res.json();
      })
  },
};

export default ApiService;
